import { Component } from 'react'
import Select from "react-select"
import SelectStyles from "../../common/selectStyles"
import { HomeProps, HomeState, FormSubmission, FormSubmissionResponse, EndpointResponse, ErrorCause, ShipmentType, getDefaultHomeState, SelectOption } from "../../common/types"
import { postSubmissionV2 } from "../../api/submissions"
import { getUserConfig } from "../../api/users"
import Validator from "../../common/validation"
import QueryString from "query-string"
import { AppLabels } from "../../common/localization"
import { formatPostalCodeInput, stringTemplate, getNumKitsNeeded, getNumKitsRemaining, getKitTokensJson, getNumKitsPreviouslyOrdered } from "../../common/Helpers"
import React from 'react'
import { Spinner } from "react-bootstrap";
import Loading from "./../common/Loading"
import Constants from "../../common/constants"

export default class HomeV2 extends Component<HomeProps, HomeState> {
  doubleCheckWarningRef: any;
  
  constructor(props: HomeProps) {
    super(props);

    let params = QueryString.parse(window.location.search)

    let lang = Array.isArray(params.lang) ? params.lang[0].toLocaleLowerCase() : (params.lang || "en").toLocaleLowerCase()
    AppLabels.setLanguage(lang)

    this.state = getDefaultHomeState(lang);

    this.state.data.UserID = Array.isArray(params.userID) ? params.userID[0] : (params.userID || undefined)
    this.state.data.IsValidUser = Array.isArray(params.iswithsixweeksflow) ? params.iswithsixweeksflow[0] === "true" : (params.iswithsixweeksflow === "true" || false)

    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);

    this.doubleCheckWarningRef = React.createRef()  
  }

  async componentDidMount() {
    try {
      if(this.state.data.UserID !== undefined && this.state.userConfig === undefined) {
        let userConfig = await getUserConfig(this.state.data.UserID!);
        let hivKitsRemaining = getNumKitsRemaining(userConfig.kitsOrdered, Constants.HivKitCode)
        let hivKitsOrdered = getNumKitsPreviouslyOrdered(userConfig.kitsOrdered, Constants.HivKitCode)

        let userAmountNeededOptions: SelectOption[] = []

        if(hivKitsOrdered > 0) {
          userAmountNeededOptions.push({ label: '0', value: 0 }) // Allow 0 HIV kits if they've already ordered an HIV kit before (let's them order COVID kit alone)
        }

        if(hivKitsRemaining > 0) {
          for(var i = 1; i <= hivKitsRemaining; i++) {
            userAmountNeededOptions.push({ label: i.toFixed(0), value: i })
          }
        }

        let data = this.state.data
        
        userConfig.kitsAvailable.forEach(x => {
          data.Kits.push({
            KitTypeCode: x.key,
            NumNeeded: Math.min(1, getNumKitsRemaining(userConfig.kitsOrdered, x.key))
          })
        })

        this.setState({
          ready: true,
          userConfig: userConfig,
          locationOptions: this.state.provinces.map(x => {
            return {
              label: x.label,
              value: x.value,
              options: userConfig.locations.filter(y => x.value === y.provinceCode).sort((y, z) => y.name.localeCompare(z.name)).map(x => {
                return { value: x.id, label: x.name }
              })
            }
          }),
          amountNeededOptions: userAmountNeededOptions,
          data: data
        })
      } else {
        this.setState({
          ready: true
        });
      }
    } catch(err: any) {
      this.setState({
        serverError: err
      })
    }
  }

  handleInputChange = (event: any) => {
    const target = event.target;

    var value = target.value

    if(target.name === "PostalCode") {
      value = formatPostalCodeInput(value)
    }

    if(target.name === "ShipmentType") {
      this.setState({ confirmAddress: false });
    }

    this.setState({
      data: {
        ...this.state.data,
        [target.name]: value
      }
    });
  }

  trimInputs = (data: FormSubmission) => {
    
    data.FirstName = data.FirstName.trim()
    data.LastName = data.LastName.trim()
    data.StreetName = data.StreetName.trim()
    data.BuildingNumber = data.BuildingNumber.trim()
    data.Unit = data.Unit.trim()
    data.City = data.City.trim()
    data.PostalCode = data.PostalCode.trim()

    this.setState({
      data
    })
  }

  clearDeliveryInputs = (data: FormSubmission) => {

    data.FirstName = ''
    data.LastName = ''
    data.StreetName = ''
    data.BuildingNumber = ''
    data.Unit = ''
    data.City = ''
    data.PostalCode = ''
    data.Province = ''

    this.setState({
      data
    });
  }

  clearPickupInputs = (data: FormSubmission) => {

    data.LocationID = 0

    this.setState({
      data
    });
  }

  validateFormSubmission() : boolean {
    this.setState({ validated: true });
    return Validator.validateFormSubmission(this.state.data, this.state.userConfig!, true, true) 
  }

  handleSubmit = (event: any) => {
    event.preventDefault();
    let { data, confirmAddress, isSaving, lang } = this.state

    // Trim all inputs
    this.trimInputs(data);

    if (!this.validateFormSubmission() || isSaving) {
      return;
    }

    // If submitting a pickup form make sure the customer address fields are wiped
    if(data.ShipmentType === ShipmentType.Pickup) {
      this.clearDeliveryInputs(data);
    } else {
      this.clearPickupInputs(data);
    }

    if(data.ShipmentType === ShipmentType.Delivery && !confirmAddress) {
      this.setState({ confirmAddress: true })
      this.doubleCheckWarningRef.current.scrollIntoView()
      return;
    }

    //check if kits contains both HIV and COVID kits arrays for plazus' side of things
    let hivKit = data.Kits.find(x => x.KitTypeCode === Constants.HivKitCode)
    let covidKit = data.Kits.find(x => x.KitTypeCode === Constants.CovidKitCode)

    //if HIV or covid kit is not in the array, add it with 0 quantity
    if(hivKit === undefined) {
      data.Kits.push({ KitTypeCode: Constants.HivKitCode, NumNeeded: 0 })
    }
    if(covidKit === undefined) {
      data.Kits.push({ KitTypeCode: Constants.CovidKitCode, NumNeeded: 0 })
    }
    
    this.setState({ isSaving: true })

    postSubmissionV2(lang, data)
      .then((resp: any) => {
        let responseData: EndpointResponse = resp.data
        let serverError = ""

        if(responseData.status) {
          this.setState({ 
            isSaving: false,
            hasSubmitted: true,
            generatedTokensJson: getKitTokensJson(responseData.data as FormSubmissionResponse)
          })
        } else {
          if(responseData.reason === ErrorCause.TooManyKitsRequested) {
            if(responseData.data !== null && 'numKitsAlreadyOrdered' in responseData.data) {
              let numKitsOrdered = Number(responseData.data.numKitsAlreadyOrdered)

              if(numKitsOrdered === 3) {
                serverError = stringTemplate(AppLabels.CantSubmitErrorReceivedAll, {
                  '1': 3
                });
              } else {
                serverError = stringTemplate(AppLabels.CantSubmitErrorReceivedSome, {
                  '1': numKitsOrdered,
                  '2': 3 - numKitsOrdered
                });
              }
            } 
            else
            {
              serverError = AppLabels.CantSubmitErrorInternal;
              console.error("Error - too many kits ordered but no amount returned")
            }
          } else if(responseData.reason === ErrorCause.SubmissionDisabled) {
            serverError = AppLabels.NotLiveNotice;
          }

          this.setState({ 
            isSaving: false,
            hasSubmitted: false,
            serverError: serverError
          })
        }
      }).catch(err => {
          let message = err !== undefined && err.response !== undefined && err.response.data !== undefined && err.response.data.length > 0
            ? err.response.data
            : AppLabels.CantSubmitErrorInternal

        this.setState({ 
          isSaving: false,
          hasSubmitted: false,
          serverError: message
        })
      });
  };

  render() {
    let { data, hasSubmitted, validated, serverError, generatedTokensJson, confirmAddress, isSaving, ready, yesNoOptions, pickupProvince } = this.state

    if(!ready) {
      return (<div className="my-3">
          <Loading label="Loading form..." color="primary"></Loading>
        </div>)
    }

    if(hasSubmitted) {
      console.log(`ImReadyForm ${JSON.stringify(generatedTokensJson)}`)
      return (
        <div className="my-3">
          <p dangerouslySetInnerHTML={{__html: AppLabels.FormSubmitted.replace(/(<? *script)/gi, 'illegalscript')}}></p>
        </div>
      )
    }

    if(data.UserID === undefined) {
      return (
        <div className="my-3">
          <div className="alert alert-danger">No User ID given.</div>
        </div>
      )
    }

    let { kitsAvailable, kitsOrdered } = this.state.userConfig!
    const covidKitsAvailable = kitsAvailable.some(x => x.key === Constants.CovidKitCode) 
      && kitsAvailable.find(x => x.key === Constants.CovidKitCode)!.enabled
      && kitsAvailable.find(x => x.key === Constants.CovidKitCode)!.maxKitsCanUserOrder > 0
    const covidKitOrdered = covidKitsAvailable && getNumKitsRemaining(kitsOrdered, Constants.CovidKitCode) <= 0

    return (
      <div className="my-3">
        <form onSubmit={this.handleSubmit}>
          {data.IsValidUser? <p>{AppLabels.UserIsValid}</p> : <p>{<p dangerouslySetInnerHTML={{__html: AppLabels.UserNotValid.replace(/(<? *script)/gi, 'illegalscript')}} ></p>}</p>}

          <div className='mb-3'>
            <label className="mb-0" htmlFor="shipment-type">{AppLabels.ShipmentType}</label>
            <Select id="shipment-type"
              styles={SelectStyles}
              options={this.state.shippingTypeOptions}
              name="ShipmentType"
              value={this.state.shippingTypeOptions.filter(x => x.value === data.ShipmentType)}
              isSearchable={false}
              onChange={(value: any, event: any) => this.handleInputChange({ target: { ...event, value: value.value }})}
            ></Select>
          </div>

          <div className="mb-3">
            {AppLabels.UserID} <span className="font-weight-bold">{data.UserID}</span>
          </div>
          <div className='mb-3'>
            <label className="mb-0" htmlFor="num-kits-required">{AppLabels.HowManyKits}</label>
            <Select id="num-kits-required"
              styles={SelectStyles}
              options={this.state.amountNeededOptions}
              name="AmountNeeded"
              value={this.state.amountNeededOptions.filter(x => x.value === getNumKitsNeeded(data.Kits, Constants.HivKitCode))}
              isSearchable={false}
              onChange={(value: any) => {
                data.Kits.find(x => x.KitTypeCode === Constants.HivKitCode)!.NumNeeded = value.value
                this.setState({ data: data })
              }}
            ></Select>
            <div className={`text-danger ${validated && !Validator.isKitQuantityValid(Constants.HivKitCode, data.Kits, this.state.userConfig!) ? "" : "d-none"}`}>{AppLabels.HowManyKitsValidation}</div>
          </div>

          {data.ShipmentType === ShipmentType.Pickup &&
            <>
              <div className="mb-3">
                <span className="font-weight-bold">{AppLabels.Location}</span>
              </div>
              <div className="row">
                <div className="col-12 col-sm-6">
                  <div className="mb-3">
                    <label className="mb-0" htmlFor="pickupProvince">{AppLabels.Province}</label>
                    <Select id="pickupProvince"
                      styles={SelectStyles}
                      options={this.state.provinces}
                      name="pickupProvince"
                      value={this.state.provinces.find(x => x.value === pickupProvince)}
                      isSearchable={false}
                      onChange={(value: any, event: any) => this.setState({ pickupProvince: value.value })}
                    ></Select>
                  </div>
                </div>
                <div className="col-12 col-sm-6">
                  <div className="mb-3">
                    <label className="mb-0 required" htmlFor="locationID">{AppLabels.PickupLocation}</label>
                    <Select id="locationID"
                      styles={SelectStyles}
                      options={pickupProvince.length > 0 && this.state.locationOptions.some(x => x.value === pickupProvince)
                        ? this.state.locationOptions.find(x => x.value === pickupProvince)?.options 
                        : this.state.locationOptions}
                      name="LocationID"
                      value={this.state.locationOptions.flatMap(x => x.options).find(x => x.value === data.LocationID)}
                      isSearchable={false}
                      onChange={(value: any, event: any) => this.handleInputChange({ target: { ...event, value: value.value }})}
                    ></Select>
                    <div className={`text-danger ${validated && data.LocationID <= 0 ? "" : "d-none"}`}>{AppLabels.LocationValidation}</div>
                  </div>
                </div>
              </div>
            </>
          }

          {covidKitsAvailable && 
            <div className="mb-3">
              <label className="mb-0 required" htmlFor="covidKit">{AppLabels.CovidKit}</label>
              {covidKitOrdered 
                ? <input className="form-control" value="No" type="text" disabled={true} />
                : <Select id="covidKit"
                  styles={SelectStyles}
                  options={yesNoOptions}
                  name="CovidKit"
                  value={yesNoOptions.filter(x => x.value === getNumKitsNeeded(data.Kits, Constants.CovidKitCode))}
                  isSearchable={false}
                  onChange={(value: any) => {
                    data.Kits.find(x => x.KitTypeCode === Constants.CovidKitCode)!.NumNeeded = value.value
                    this.setState({ data: data })
                  }}
                ></Select>
              }
              {covidKitOrdered && <div className="alert alert-warning mt-3">{AppLabels.CovidKitOrdered}</div>}
            </div>
          }

          {data.ShipmentType === ShipmentType.Pickup &&
            <div className="mb-3" dangerouslySetInnerHTML={{__html: AppLabels.PickupInventoryWarning}}></div>
          }



          {data.ShipmentType === ShipmentType.Delivery && 
          <div>
            <div 
              id='double-check-warning' 
              ref={ this.doubleCheckWarningRef } 
              className={confirmAddress ? "alert alert-warning mb-3" : "mb-3"} 
              dangerouslySetInnerHTML={{__html: confirmAddress ? AppLabels.DoubleCheckWarningConfirm : AppLabels.DoubleCheckWarningSubmit }}></div>

            <div className='mb-2 form-title'
                dangerouslySetInnerHTML={{ __html: AppLabels.NameTitle }}>
            </div>
            <div className="mb-3 row">
              <div className="col-12 col-md-6">
                <div className="mb-3">
                  <label className="mb-0 required" htmlFor="first-name">{AppLabels.FirstName}</label>
                  <input id="first-name" 
                    className={`form-control ${validated && data.FirstName.trim().length <= 0 ? "border-danger" : ""}`} 
                    name="FirstName"
                    value={data.FirstName}
                    type="text" 
                    placeholder="John"
                    onChange={(event) => this.handleInputChange(event)} />
                  <div className={`text-danger ${validated && data.FirstName.trim().length <= 0 ? "" : "d-none"}`}>{AppLabels.FirstNameValidation}</div>
                </div>
              </div>
              <div className="col-12 col-md-6">
                <div>
                  <label className="mb-0 required" htmlFor="last-name">{AppLabels.LastName}</label>
                  <input id="last-name" 
                    className={`form-control ${validated && data.LastName.trim().length <= 0 ? "border-danger" : ""}`} 
                    name="LastName"
                    value={data.LastName}
                    type="text" 
                    placeholder="Doe"
                    onChange={(event) => this.handleInputChange(event)} />
                  <div className={`text-danger ${validated && data.LastName.trim().length <= 0 ? "" : "d-none"}`}>{AppLabels.LastNameValidation}</div>
                </div>
              </div>
            </div>

            <div className='mb-2'>
              <div className="form-title">{AppLabels.AddressTitle}</div>
              <ul className="inset-list"><li>{AppLabels.AddressSubtitle}</li></ul>
            </div>

            <div className="row">
              <div className="col-12 col-md-6">
                <div className="mb-3">
                  <label className="mb-0 required" htmlFor="building-number">{AppLabels.BuildingNumber}</label>
                  <input 
                    id="building-number" 
                    className={`form-control ${validated && data.BuildingNumber.trim().length < 1 ? "border-danger" : ""}`} 
                    name="BuildingNumber"
                    value={data.BuildingNumber}
                    type="text" 
                    placeholder="1"
                    onChange={(event) => this.handleInputChange(event)} />
                  <div className={`text-danger ${validated && data.BuildingNumber.trim().length < 1 ? "" : "d-none"}`}>{AppLabels.BuildingNumberValidation}</div>
                </div>
              </div>
              <div className="col-12 col-md-6">
                <div className="mb-3">
                  <label className="mb-0 required" htmlFor="street-name">{AppLabels.StreetName}</label>
                  <input 
                    id="street-name" 
                    className={`form-control ${validated && data.StreetName.trim().length < 3 ? "border-danger" : ""}`} 
                    name="StreetName"
                    value={data.StreetName}
                    type="text" 
                    placeholder={AppLabels.StreetNamePlaceholder}
                    onChange={(event) => this.handleInputChange(event)} />
                  <div className={`text-danger ${validated && data.StreetName.trim().length < 3 ? "" : "d-none"}`}>{AppLabels.StreetNameValidation}</div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-12 col-md-6">
                <div className="mb-3">
                  <label className="mb-0" htmlFor="unit">{AppLabels.Unit}</label>
                  <input 
                    id="unit" 
                    className="form-control" 
                    name="Unit"
                    value={data.Unit}
                    type="text" 
                    placeholder="1"
                    onChange={(event) => this.handleInputChange(event)} />
                </div>
              </div>
              <div className="col-12 col-md-6">
                <div className="mb-3">
                  <label className="mb-0 required" htmlFor="city">{AppLabels.City}</label>
                  <input 
                    id="city" 
                    className={`form-control ${validated && data.City.trim().length < 3 ? "border-danger" : ""}`} 
                    name="City"
                    value={data.City}
                    type="text" 
                    placeholder={AppLabels.CityPlaceholder}
                    onChange={(event) => this.handleInputChange(event)} />
                  <div className={`text-danger ${validated && data.City.trim().length < 3 ? "" : "d-none"}`}>{AppLabels.CityValidation}</div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-12 col-md-6">
                <div className="mb-3">
                  <label className="mb-0 required" htmlFor="province">{AppLabels.Province}</label>
                  <Select id="province"
                    styles={SelectStyles}
                    options={this.state.provinces}
                    name="Province"
                    value={this.state.provinces.filter(x => x.value === data.Province)}
                    isSearchable={false}
                    onChange={(value: any, event: any) => this.handleInputChange({ target: { ...event, value: value.value }})}
                  ></Select>
                  <div className={`text-danger ${validated && data.Province.trim().length < 2 ? "" : "d-none"}`}>{AppLabels.ProvinceValidation}</div>
                </div>
              </div>

              <div className="col-12 col-md-6">
                <div className="mb-3">
                  <label className="mb-0 required" htmlFor="postal-code">{AppLabels.PostalCode}</label>
                  <input 
                    id="postal-code" 
                    className={`form-control ${validated && data.PostalCode.trim().length !== 7 ? "border-danger" : ""}`} 
                    name="PostalCode"
                    value={data.PostalCode}
                    type="text" 
                    placeholder="A1A 1A1"
                    onChange={(event) => this.handleInputChange(event)} />
                  <div className={`text-danger ${validated && data.PostalCode.trim().length !== 7 ? "" : "d-none"}`}>{AppLabels.PostalCodeValidation}</div>
                </div>
              </div>
            </div>
          </div>}

            {serverError !== undefined && serverError.trim().length > 0 && (
              <p className="mb-3 alert alert-danger" dangerouslySetInnerHTML={{__html: serverError.replace(/(<? *script)/gi, 'illegalscript')}}></p>
            )}

          <div className="mb-3">
            {isSaving 
            ? <button type="submit" disabled className="btn btn-secondary w-100"><Spinner animation="border" variant="primary" className="me-2" size="sm" />{AppLabels.Saving}</button>
            : <button type="submit" disabled={!data.IsValidUser} className="btn btn-secondary w-100">{confirmAddress ? AppLabels.Confirm : AppLabels.Submit}</button>

            }</div>
        </form>
      </div>
    )
  }
}