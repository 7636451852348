import LocalizedStrings, { LocalizedStringsMethods } from "react-localization"

export interface ILabels extends LocalizedStringsMethods {
  Welcome: string
  NotLiveNotice: string

  ShipmentType: string
  ShipmentTypePickup: string
  ShipmentTypeDelivery: string

  ShipWithinCanadaNotice: string

  HowManyKits: string
  HowManyKitsV3: string
  HowManyKitsValidation: string
  UserID: string
  CovidKit: string
  CovidKitOrdered: string
  PickupInventoryWarning: string

  Location: string
  PickupLocation: string
  LocationValidation: string

  NameTitle: string
  FirstName: string
  FirstNameValidation: string
  LastName: string
  LastNameValidation: string

  DoubleCheckWarningSubmit: string
  DoubleCheckWarningConfirm: string
  AddressTitle: string
  AddressSubtitle: string
  StreetName: string
  StreetNamePlaceholder: string
  StreetNameValidation: string
  BuildingNumber: string
  BuildingNumberValidation: string
  Unit: string
  City: string
  CityPlaceholder: string
  CityValidation: string
  PostalCode: string
  PostalCodeValidation: string
  Province: string
  ProvinceValidation: string

  Confirm: string
  Submit: string
  Saving: string

  FormSubmitted: string
  FormV3Submitted: string
  CantSubmitErrorInternal: string
  CantSubmitErrorReceivedAll: string
  CantSubmitErrorReceivedSome: string

  Alberta: string
  BritishColumbia: string
  Manitoba: string
  NewBrunswick: string
  NewfoundlandAndLabrador: string
  NovaScotia: string
  Ontario: string
  PrinceEdwardIsland: string
  Quebec: string
  Saskatchewan: string
  NorthwestTerritories: string
  Nunavut: string
  Yukon: string

  Yes: string
  No: string

  UserNotValid: string,
  UserIsValid: string,

  IAMHowManyKits: string,
  IAMHowManyKitsValidation: string,
  IAMFormSubmittedOrderSubmitted: string,
  IAMFormSubmittedThankYou: string,
  IAMFormSubmittedReturn: string

}

export const AppLabels: ILabels = new LocalizedStrings({
  en: {
    Welcome: "Welcome to the HIV self-test kit order form for the <a href='https://readytoknow.ca/ready-get-a-kit/im-ready-research-program/'>I'm Ready research program.</a> You can order a total of 3 free kits from this program. You don't have to order them all at once. You can either order your kits for delivery to your home or another address, or you can pick them up at one of our <a href='https://readytoknow.ca/ready-get-a-kit/pickup-locations/'>pickup locations.</a> Please note that you can't pick up kits at a pickup location without ordering them first through this form.",
    NotLiveNotice: "<strong>NOTICE:</strong> We're getting ready to launch the I'm Ready research program but we’re not live yet and neither is this order form. Follow us on Twitter, Facebook and Instagram at @ImReadyJagis for updates.",

    ShipmentType: "Do you want to order your kit(s) for pick up or for delivery?",
    ShipmentTypePickup: "Pick up my kit(s)",
    ShipmentTypeDelivery: "Have my kit(s) delivered to my home or another address",

    ShipWithinCanadaNotice: "I AM is able to ship INSTI HIV self-test kits to addresses located in Canada ONLY",

    HowManyKits: "How many HIV self-test kits do you want? You can order a total of 3 free kits from this research program. You don't have to order them all now.",
    HowManyKitsV3: "How many HIV self-test kits do you want? You can order a total of 5 free kits from this research program",
    HowManyKitsValidation: "The number of self-test kits must be between 1 and 3.",
    UserID: "Unique user ID (from App):",
    CovidKit: "Would you like one package of COVID antigen test kits?",
    CovidKitOrdered: "You have already ordered the one COVID antigen test avaliable to you through the I'm Ready research program.",
    PickupInventoryWarning: "Due to changes in inventory, please contact the pickup site for test availability prior to coming to the site.  For contact information please see: <a href='https://www.readytoknow.ca/ready-get-a-kit/pickup-locations/'>https://www.readytoknow.ca/ready-get-a-kit/pickup-locations/</a>.",

    Location: "Which of the I'm Ready pickup locations do you intend to pickup your test kits from:",
    PickupLocation: "Pickup Location",
    LocationValidation: "Location is required.",

    NameTitle: "Please enter a first and last name. <strong>These can be your legal names or you can make them up.</strong>",
    FirstName: "First name",
    FirstNameValidation: "First name is required. This can be your legal name or you can make one up.",
    LastName: "Last name",
    LastNameValidation: "Last name is required. This can be your legal name or you can make one up.",

    DoubleCheckWarningSubmit: "<strong>Please double check that you have entered your correct mailing address before pressing \"submit\" on this form.</strong> We ship with Canada Post and want to make sure the tests get to you. Please be aware that we cannot make changes to your mailing address once your order has been submitted, and can't do anything about missing or misdirected packages.",
    DoubleCheckWarningConfirm: "<strong>Please double check that you have entered your correct mailing address before pressing \"confirm\" on this form.</strong> We ship with Canada Post and want to make sure the tests get to you. Please be aware that we cannot make changes to your mailing address once your order has been submitted, and can't do anything about missing or misdirected packages.",
    AddressTitle: "Please enter the address that you want your kit(s) delivered to. This can be your home or another address.",
    AddressSubtitle: "Note: We use your address information only to send you a kit. It's not stored on this app and won't be linked to the data we collect for the research study",
    BuildingNumber: "Building number (or PO box address) ",
    BuildingNumberValidation: "Building number or PO box address is required.",
    StreetName: "Street name (or station code, if using a PO box) ",
    StreetNamePlaceholder: "Street",
    StreetNameValidation: "Street name, or station number if you're using a PO box, is required.",
    Unit: "Unit",
    City: "City",
    CityPlaceholder: "City",
    CityValidation: "City is required.",
    PostalCode: "Postal code",
    PostalCodeValidation: "Postal code is required.",
    Province: "Province",
    ProvinceValidation: "Province is required.",

    Confirm: "Confirm",
    Submit: "Submit",
    Saving: "Saving",

    FormSubmitted: "Thank you! You will receive your kit(s) within two weeks if you ordered them for delivery. If you chose to pick up your kit(s), visit any of our <a href='https://readytoknow.ca/ready-get-a-kit/pickup-locations/'>pickup locations</a> to claim your kits using the self-test kit pickup codes you can find in the menu screen of the I'm Ready, Test app. This is at the bottom-right of your screen when you have the app open.",
    FormV3Submitted: "Thank you! Your kits are on the way!",
    CantSubmitErrorInternal: "Error submitting form, internal server error",
    CantSubmitErrorReceivedAll: "Thanks for being part of the I'm Ready research program. Unfortunately, we have a limited number of free HIV self-testing kits and you have already ordered your maximum of {1}. Is there anything we can do to support you or connect you to other resources for testing, care and prevention? We suggest booking an appointment with one of our peer navigators through our <a href='https://connect.im-ready.ca/booking/steps/matching'>I'm Ready, Talk secure telehealth service.</a>",
    CantSubmitErrorReceivedSome: "You can order a total of 3 kits as an I’m Ready research program participant. You have already ordered {1}, so you can order {2} more.",

    Alberta: "Alberta",
    BritishColumbia: "British Columbia",
    Manitoba: "Manitoba",
    NewBrunswick: "New Brunswick",
    NewfoundlandAndLabrador: "Newfoundland and Labrador",
    NovaScotia: "Nova Scotia",
    Ontario: "Ontario",
    PrinceEdwardIsland: "Prince Edward Island",
    Quebec: "Quebec",
    Saskatchewan: "Saskatchewan",
    NorthwestTerritories: "Northwest Territories",
    Nunavut: "Nunavut",
    Yukon: "Yukon",

    Yes: "Yes",
    No: "No",
    UserNotValid: "<strong>Attention! We have important updates for you</strong>. To ensure the best experience with our app, please <strong>update to the latest version</strong>. Once you've updated, you'll be prompted to switch to our new program for even more opportunities to get resources, free HIV self-test kits and linkage to care information. Please note that this will require an additional update. If you choose to switch to the new program, your data stored on the app will be deleted. To get started, simply click on the link for <a href='https://apps.apple.com/us/app/im-ready-test/id1547497367/'>iOS</a> or <a href='https://play.google.com/store/apps/details?id=com.unityhealthtoronto.imreadytest&hl=en&gl=US&pli=1/'>Android<a/> and follow the instructions.",
    UserIsValid: "Welcome to the HIV self-test kit order form for the I'm Ready program. You can order a total of 3 free HIV kits from this program. You don't have to order them all at once. You can either order your kits for delivery to your home or another address, or you can pick them up at one of our pickup locations. Please note that you can't pick up kits at a pickup location without ordering the first through this form.",

    IAMHowManyKits: "How many HIV self-test kits do you want? You can order up to 5 free kits.",
    IAMHowManyKitsValidation: "The number of self-test kits must be between 1 and 5.",
    IAMFormSubmittedOrderSubmitted: "Your order form has been submitted successfully",
    IAMFormSubmittedThankYou: "THANK YOU!",
    IAMFormSubmittedReturn: "Please use the main navigation to continue exploring the I AM website"

  },
  fr: {
    Welcome: "Bienvenue au formulaire de commande de trousse d’autodépistage du VIH pour le <a href='https://readytoknow.ca/fr/pret-obtenez-une-trousse/programme-de-recherche-jagis/'>programme de recherche J’agis</a>. Vous pouvez commander un total de trois trousses gratuites auprès de ce programme. Vous n’avez pas à les commander toutes en une seule fois. Vous pouvez commander vos trousses pour la livraison à votre domicile ou à une autre adresse, ou vous pouvez les récupérer à l’un de nos <a href='https://readytoknow.ca/fr/pret-obtenez-une-trousse/lieux-de-cueillette/'>lieux de cueillette</a>. Veuillez noter que vous ne pouvez pas récupérer les trousses à un point de cueillette sans d’abord les commander à l’aide de ce formulaire.",
    NotLiveNotice: "<strong>AVIS :</strong> Nous sommes prêts à lancer le programme de recherche J’agis, mais le site Web et ce formulaire de commande n’ont pas encore été mis en ligne. Suivez-nous sur Twitter, Facebook et Instagram à @ImReadyJagis pour obtenir des mises à jour.",

    ShipmentType: "Voulez-vous commander votre/vos trousse(s) pour la cueillette ou la livraison?",
    ShipmentTypePickup: "Récupérer ma/mes trousse(s)",
    ShipmentTypeDelivery: "Faire livrer ma/mes trousse(s) à mon domicile ou à une autre adresse",

    ShipWithinCanadaNotice: "JE SUIS/I AM peut envoyer des trousses d'autodépistage du VIH INSTI UNIQUEMENT à des adresses situées au Canada",

    HowManyKits: "Combien voulez-vous de trousses d’autodépistage du VIH? Vous pouvez commander un total de trois trousses gratuites auprès de ce programme de recherche. Vous n’avez pas à les commander toutes maintenant.",
    HowManyKitsV3: "Combien voulez-vous de trousses d’autodépistage du VIH? Vous pouvez commander un total de cinq gratuites auprès de ce programme de recherche",
    HowManyKitsValidation: "Le nombre de trousses doit être compris entre 1 et 3.",
    UserID: "Identifiant d’utilisateur unique (de l’application): ",
    CovidKit: "Voulez-vous une trousse de tests antigéniques de dépistage de la COVID-19?",
    CovidKitOrdered: "Vous avez déjà commandé le test antigénique de dépistage de la COVID-19 qui était prévu pour vous dans le cadre du programme de recherche J’agis.",
    PickupInventoryWarning: "En raison de changements dans les stocks, communiquez avec le lieu de cueillette avant de vous y rendre pour savoir si des trousses sont disponibles. Pour obtenir les coordonnées, veuillez consulter la page : <a href='https://www.readytoknow.ca/fr/pret-obtenez-une-trousse/lieux-de-cueillette/'>https://www.readytoknow.ca/fr/pret-obtenez-une-trousse/lieux-de-cueillette/</a>.",

    Location: "Dans quel lieu de cueillette J’agis avez-vous l’intention de récupérer vos trousses de dépistage :",
    PickupLocation: "Lieu de cueillette",
    LocationValidation: "Lieu requis.",

    NameTitle: "Veuillez saisir un prénom et un nom de famille. <strong>Vous pouvez utiliser vos véritables nom et prénom ou vous pouvez en inventer.</strong>",
    FirstName: "Prénom",
    FirstNameValidation: "Le prénom est requis. Il peut s’agir de votre prénom légal ou vous pouvez en inventer un.",
    LastName: "Nom de famille",
    LastNameValidation: "Le nom de famille est requis. Il peut s’agir de votre prénom légal ou vous pouvez en inventer un.",

    DoubleCheckWarningSubmit: "<strong>Veuillez vérifier de nouveau que vous avez entré correctement l’adresse postale de destination avant de cliquer sur le bouton « Soumettre » de ce formulaire. </strong>Nos colis sont expédiés par Postes Canada et nous voulons nous assurer que les tests vous parviennent. Veuillez noter que nous ne pouvons pas apporter de modifications à votre adresse postale une fois que votre commande a été soumise et que nous ne pourrons être tenus responsables des colis non ou mal acheminés.",
    DoubleCheckWarningConfirm: "<strong>Veuillez vérifier de nouveau que vous avez entré l’adresse postale correcte avant d’appuyer sur Confirmer sur ce formulaire.</strong> Nos colis sont expédiés par Postes Canada et nous voulons nous assurer que les tests d’audodépistage vous parviennent. Veuillez noter que nous ne pouvons pas apporter de modifications à votre adresse postale une fois que votre commande a été soumise et que nous ne pourrons être tenus responsables des colis manquants ou mal acheminés.",
    AddressTitle: "Veuillez entrer l’adresse à laquelle vous voulez que votre/vos trousse(s) soient livrées. Il peut s’agir de votre domicile ou d’une autre adresse.",
    AddressSubtitle: "Remarque : Nous utilisons vos coordonnées uniquement pour vous envoyer une trousse. Elles ne sont pas archivées sur cette application et ne seront pas liées aux données que nous recueillons pour l’étude de recherche.",
    StreetName: "Nom de rue (ou code de la succursale, si vous utilisez une boîte postale)",
    StreetNamePlaceholder: "Nom de rue",
    StreetNameValidation: "Le nom de rue, ou le numéro de la succursale si vous utilisez une boîte postale, est requis.",
    BuildingNumber: "Numéro d’immeuble (ou adresse de la boîte postale)",
    BuildingNumberValidation: "Le numéro d’immeuble ou l’adresse de la boîte postale est requis.",
    Unit: "Unité",
    City: "Ville",
    CityPlaceholder: "Ville",
    CityValidation: "La ville est requise.",
    PostalCode: "Code postal",
    PostalCodeValidation: "Le code postal est requis.",
    Province: "Province",
    ProvinceValidation: "La province est requis.",

    Confirm: "Confirmer",
    Submit: "Soumettre",
    Saving: "Soumettre",

    FormSubmitted: "Merci! Vous recevrez votre/vos trousse(s) dans un délai de deux semaines si vous l’avez ou les avez commandée(s) pour la livraison. Si vous choisissez de récupérer votre/vos trousse(s), visitez l’un de nos <a href='https://readytoknow.ca/fr/pret-obtenez-une-trousse/lieux-de-cueillette/'>lieux de cueillette</a> pour récupérer votre/vos trousse(s) à l’aide des codes de cueillette des trousses d’autodépistage que vous trouverez sur l’écran du menu de l’application J’agis, Je me dépiste. Lorsque l’application est ouverte, il se trouve dans le coin inférieur droit de votre écran.",
    FormV3Submitted: "Merci! Vos kits sont en route!",
    CantSubmitErrorInternal: "Erreur de serveur interne",
    CantSubmitErrorReceivedAll: "Merci de participer au programme de recherche J’agis! Malheureusement, nous disposons d’un nombre limité de trousses d’autodépistage du VIH gratuites et vous avez déjà commandé le maximum de trois trousses. Pouvons-nous faire quelque chose pour vous soutenir ou vous mettre en contact avec d’autres ressources liées au dépistage, aux soins et à la prévention? Nous suggérons de prendre rendez-vous avec l’un de nos pairs navigateurs par l’intermédiaire de notre service de télésanté sécurisé <a href='https://connect.im-ready.ca/booking/steps/matching'>J’agis - J’échange</a>.",
    CantSubmitErrorReceivedSome: "Vous pouvez commander un total de trois trousses en tant que participant au programme de recherche J’agis. Vous en avez déjà commandé {1}; vous pouvez donc en commander {2} de plus.",

    Alberta: "Alberta",
    BritishColumbia: "Colombie-Britannique",
    Manitoba: "Manitoba",
    NewBrunswick: "Nouveau-Brunswick",
    NewfoundlandAndLabrador: "Terre-Neuve-et-Labrador",
    NovaScotia: "Nouvelle-Écosse",
    Ontario: "Ontario",
    PrinceEdwardIsland: "île du Prince-Édouard",
    Quebec: "Québec",
    Saskatchewan: "Saskatchewan",
    NorthwestTerritories: "les Territoires du Nord-Ouest",
    Nunavut: "Nunavut",
    Yukon: "Yukon",

    Yes: "Oui",
    No: "Non",

    UserNotValid: "<strong>Attention ! Nous avons des nouvelles importantes à partager avec vous</strong>. Afin de profiter d’une expérience optimale avec notre application, <strong>merci de faire une mise à jour vers la dernière version</strong>. Une fois celle-ci effectuée, vous serez invité.e à embarquer sur notre nouveau programme pour avoir accès à encore plus d'opportunités d'obtenir du soutien, des trousses gratuites d'autodépistage du VIH et des informations sur l’arrimage aux soins. Veuillez prendre note que cela nécessitera une mise à jour supplémentaire. Si vous décidez de passer à notre nouveau programme, vos données stockées dans l'application seront supprimées. Pour commencer, il suffit de cliquer sur le lien pour <a href='https://apps.apple.com/us/app/im-ready-test/id1547497367/'>iOS</a> ou celui pour <a href='https://play.google.com/store/apps/details?id=com.unityhealthtoronto.imreadytest&hl=en&gl=US&pli=1/'>Android<a/> et de suivre les instructions.",
    UserIsValid: "Bienvenue sur le formulaire de commande de trousses d'autodépistage du VIH du programme 'J’agis'. Vous pouvez commander un total de 3 trousses gratuites d'autodépistage du VIH dans le cadre de ce programme. Vous n'êtes pas obligé de toutes les commander en même temps. Vous pouvez commander vos trousses et les faire livrer à votre domicile ou à une autre adresse, ou vous pouvez les récupérer dans l'un de nos points de collecte. Veuillez noter qu'il n'est pas possible de retirer des trousses à un point de collecte sans en avoir commandé au moins un à l'aide de ce formulaire.",

    IAMHowManyKits: "Combien voulez-vous de trousses d’autotest du VIH? Vous pouvez commander jusqu'à cinq trousses gratuites.",
    IAMHowManyKitsValidation: "Le nombre de trousses doit être compris entre 1 et 5.",
    IAMFormSubmittedOrderSubmitted: "Votre formulaire de commande a bien été pris en compte",
    IAMFormSubmittedThankYou: "MERCI!",
    IAMFormSubmittedReturn: "Merci d'utiliser le menu principal pour poursuivre la visite du site web JE SUIS/I AM"

  }
});