import { AppLabels } from './localization'

export type SelectOption = {
  label: any
  value: any
}

export type SelectOptionGroup = {
  label: any
  value: string
  options: SelectOption[]
}

export enum ErrorCause {
  Unknown = 1,
  TooManyKitsRequested = 2,
  SubmissionDisabled = 3,
}

export enum ShipmentType {
  Pickup = 1,
  Delivery = 2,
}

export class FormSubmission {
  UserID?: string = undefined
  ShipmentType: ShipmentType = ShipmentType.Pickup
  FirstName: string = ''
  LastName: string = ''
  StreetName: string = ''
  BuildingNumber: string = ''
  Unit: string = ''
  City: string = ''
  PostalCode: string = ''
  Province: string = ''
  LocationID: number = 0
  Kits: FormSubmissionKit[] = []
  IsValidUser: boolean = false
}

export class FormSubmissionKit {
  KitTypeCode: string = ''
  NumNeeded: number = 0
}

export class FormSubmissionResponse {
  kits: FormSubmissionResponseKit[] = []
}

export class FormSubmissionResponseKit {
  kitTypeCode: string = ''
  codes: string[] = []
}

export class EndpointResponse {
  status: boolean = false
  reason: ErrorCause = ErrorCause.Unknown
  data: any
}

export class UserConfig {
  locations: Location[] = []
  kitsAvailable: KitType[] = []
  kitsOrdered: UserKit[] = []
}

export class Location {
  id: number = 0
  name: string = ''
  key: string = ''
  enabled: boolean = true
  provinceCode: string = ''
}

export class KitType {
  name: string = ''
  key: string = ''
  enabled: boolean = true
  maxKitsCanUserOrder: number = 0
}

export class UserKit {
  name: string = ''
  key: string = ''
  numKitsOrdered: number = 0
  quotaRemaining: number = 0
}

export interface HomeProps {}

export interface HomeState {
  data: FormSubmission
  isSaving: boolean
  hasSubmitted: boolean
  amountNeededOptions: SelectOption[]
  shippingTypeOptions: SelectOption[]
  provinces: SelectOption[]
  validated: boolean
  generatedTokensJson: KitCodesJson
  serverError: string
  confirmAddress: boolean
  ready: boolean
  userConfig?: UserConfig
  yesNoOptions: SelectOption[]
  locationOptions: SelectOptionGroup[]
  pickupProvince: string
  lang: string
}

export function getDefaultHomeState(lang: string): HomeState {
  return {
    data: new FormSubmission(),
    isSaving: false,
    hasSubmitted: false,
    amountNeededOptions: [
      { value: 1, label: '1' },
      { value: 2, label: '2' },
      { value: 3, label: '3' },
    ],
    shippingTypeOptions: [
      { value: ShipmentType.Pickup, label: AppLabels.ShipmentTypePickup },
      { value: ShipmentType.Delivery, label: AppLabels.ShipmentTypeDelivery },
    ],
    provinces: [
      { value: 'AB', label: AppLabels.Alberta },
      { value: 'BC', label: AppLabels.BritishColumbia },
      { value: 'MB', label: AppLabels.Manitoba },
      { value: 'NB', label: AppLabels.NewBrunswick },
      { value: 'NL', label: AppLabels.NewfoundlandAndLabrador },
      { value: 'NS', label: AppLabels.NovaScotia },
      { value: 'ON', label: AppLabels.Ontario },
      { value: 'PE', label: AppLabels.PrinceEdwardIsland },
      { value: 'QC', label: AppLabels.Quebec },
      { value: 'SK', label: AppLabels.Saskatchewan },
      { value: 'NT', label: AppLabels.NorthwestTerritories },
      { value: 'NU', label: AppLabels.Nunavut },
      { value: 'YT', label: AppLabels.Yukon },
    ],
    validated: false,
    serverError: '',
    generatedTokensJson: {},
    confirmAddress: false,
    ready: false,
    yesNoOptions: [
      { value: 1, label: AppLabels.Yes },
      { value: 0, label: AppLabels.No },
    ],
    locationOptions: [],
    pickupProvince: '',
    lang: lang,
  }
}

export interface KitCodesJson {
  [key: string]: string[]
}
