import { Component } from 'react'
import { Route } from 'react-router'
import { BrowserRouter } from 'react-router-dom'
import { Layout } from './Layout'
import Home from '../Home'
import HomeV2 from '../v2/Home'
import HomeV3 from '../v3/Home'
import HomeIAM from '../iam/Home'

export default class App extends Component {
  render() {
    return (
      <BrowserRouter>
        <Layout>
          <Route exact path='/' component={Home} />
          <Route exact path='/v2' component={HomeV2} />
          <Route exact path='/v3' component={HomeV3} />
          <Route exact path='/iam' component={HomeIAM} />
        </Layout>
      </BrowserRouter>
    )
  }
}
