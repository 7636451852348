import axios from "axios";
import { FormSubmission } from "../common/types";

const action = "formSubmissions";

export async function postSubmission(culture: string, submission: FormSubmission): Promise<any> {
  return await axios.post(`/api/${action}?culture=${culture}`, submission)
}

export async function postSubmissionV2(culture: string, submission: FormSubmission): Promise<any> {
  return await axios.post(`/api/${action}/v2?culture=${culture}`, submission)
}

export async function postSubmissionV3(culture: string, submission: FormSubmission): Promise<any> {
  return await axios.post(`/api/${action}/v3?culture=${culture}`, submission)
}

export async function postSubmissionIAM(culture: string, submission: FormSubmission): Promise<any> {
  return await axios.post(`/api/${action}/iam?culture=${culture}`, submission)
}