import { Component } from 'react'
import Select from "react-select"
import SelectStyles from "../../common/selectStyles"
import { HomeProps, HomeState, FormSubmission, FormSubmissionResponse, EndpointResponse, ErrorCause, ShipmentType, getDefaultHomeState, SelectOption } from "../../common/types"
import { postSubmissionIAM } from "../../api/submissions"
import { getUserConfig } from "../../api/users"
import Validator from "../../common/validation"
import QueryString from "query-string"
import { AppLabels } from "../../common/localization"
import { formatPostalCodeInput, stringTemplate, getKitTokensJson } from "../../common/Helpers"
import React from 'react'
import { Spinner } from "react-bootstrap";
import Loading from "./../common/Loading"
import Constants from "../../common/constants"

export default class HomeIAM extends Component<HomeProps, HomeState> {
  doubleCheckWarningRef: any;

  constructor(props: HomeProps) {
    super(props);

    let params = QueryString.parse(window.location.search)

    let lang = Array.isArray(params.lang) ? params.lang[0].toLocaleLowerCase() : (params.lang || "en").toLocaleLowerCase()
    AppLabels.setLanguage(lang)

    this.state = getDefaultHomeState(lang);

    this.state.data.UserID = "IAM";
    this.state.data.ShipmentType = ShipmentType.Delivery;

    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);

    this.doubleCheckWarningRef = React.createRef()
  }

  async componentDidMount() {
    try {
      if (this.state.data.UserID !== undefined && this.state.userConfig === undefined) {
        let userConfig = await getUserConfig(this.state.data.UserID!);

        let userAmountNeededOptions: SelectOption[] = []

        for (var i = 1; i <= 5; i++) {
          userAmountNeededOptions.push({ label: i.toFixed(0), value: i })
        }

        let data = this.state.data

        userConfig.kitsAvailable.forEach(x => {
          data.Kits.push({
            KitTypeCode: x.key,
            NumNeeded: 1
          })
        })

        this.setState({
          ready: true,
          userConfig: userConfig,
          amountNeededOptions: userAmountNeededOptions,
          data: data
        })
      } else {
        this.setState({
          ready: true
        });
      }
    } catch (err: any) {
      this.setState({
        serverError: err
      })
    }
  }

  handleInputChange = (event: any) => {
    const target = event.target;

    var value = target.value

    if (target.name === "PostalCode") {
      value = formatPostalCodeInput(value)
    }

    this.setState({
      data: {
        ...this.state.data,
        [target.name]: value
      }
    });
  }

  trimInputs = (data: FormSubmission) => {

    data.FirstName = data.FirstName.trim()
    data.LastName = data.LastName.trim()
    data.StreetName = data.StreetName.trim()
    data.BuildingNumber = data.BuildingNumber.trim()
    data.Unit = data.Unit.trim()
    data.City = data.City.trim()
    data.PostalCode = data.PostalCode.trim()

    this.setState({
      data
    })
  }

  clearDeliveryInputs = (data: FormSubmission) => {

    data.FirstName = ''
    data.LastName = ''
    data.StreetName = ''
    data.BuildingNumber = ''
    data.Unit = ''
    data.City = ''
    data.PostalCode = ''
    data.Province = ''

    this.setState({
      data
    });
  }


  validateFormSubmission(): boolean {
    this.setState({ validated: true });
    return Validator.validateFormSubmission(this.state.data, this.state.userConfig!, false, false)
  }

  handleSubmit = (event: any) => {
    event.preventDefault();
    let { data, confirmAddress, isSaving, lang } = this.state

    // Trim all inputs
    this.trimInputs(data);

    if (!this.validateFormSubmission() || isSaving) {
      return;
    }

    if (!confirmAddress) {
      this.setState({ confirmAddress: true })
      this.doubleCheckWarningRef.current.scrollIntoView()
      return;
    }

    //check if kits contains both HIV and COVID kits arrays for plazus' side of things
    let hivKit = data.Kits.find(x => x.KitTypeCode === Constants.HivKitCode)
    let covidKit = data.Kits.find(x => x.KitTypeCode === Constants.CovidKitCode)

    //if HIV or covid kit is not in the array, add it with 0 quantity
    if (hivKit === undefined) {
      data.Kits.push({ KitTypeCode: Constants.HivKitCode, NumNeeded: 0 })
    }
    if (covidKit === undefined) {
      data.Kits.push({ KitTypeCode: Constants.CovidKitCode, NumNeeded: 0 })
    }

    this.setState({ isSaving: true })

    postSubmissionIAM(lang, data)
      .then((resp: any) => {
        let responseData: EndpointResponse = resp.data
        let serverError = ""

        if (responseData.status) {
          this.setState({
            isSaving: false,
            hasSubmitted: true,
            generatedTokensJson: getKitTokensJson(responseData.data as FormSubmissionResponse)
          })
        } else {
          if (responseData.reason === ErrorCause.TooManyKitsRequested) {
            serverError = stringTemplate(AppLabels.CantSubmitErrorReceivedAll, {
              '1': 5
            });
          } else if (responseData.reason === ErrorCause.SubmissionDisabled) {
            serverError = AppLabels.NotLiveNotice;
          } else {
            serverError = AppLabels.CantSubmitErrorInternal;
          }

          this.setState({
            isSaving: false,
            hasSubmitted: false,
            serverError: serverError
          })
        }
      }).catch(err => {
        let message = err !== undefined && err.response !== undefined && err.response.data !== undefined && err.response.data.length > 0
          ? err.response.data
          : AppLabels.CantSubmitErrorInternal

        this.setState({
          isSaving: false,
          hasSubmitted: false,
          serverError: message
        })
      });
  };

  render() {
    let { data, hasSubmitted, validated, serverError, generatedTokensJson, confirmAddress, isSaving, ready } = this.state

    if (!ready) {
      return (<div className="my-3">
        <Loading label="Loading form..." color="primary"></Loading>
      </div>)
    }

    if (hasSubmitted) {
      console.log(`ImReadyForm ${JSON.stringify(generatedTokensJson)}`)
      return (
        <div className="my-3 text-center">
          <p className='iam-confirmation-thankyou' dangerouslySetInnerHTML={{ __html: AppLabels.IAMFormSubmittedThankYou.replace(/(<? *script)/gi, 'illegalscript') }}></p>
          <p className='iam-confirmation' dangerouslySetInnerHTML={{ __html: AppLabels.IAMFormSubmittedOrderSubmitted.replace(/(<? *script)/gi, 'illegalscript') }}></p>
          <p className='iam-confirmation' dangerouslySetInnerHTML={{ __html: AppLabels.IAMFormSubmittedReturn.replace(/(<? *script)/gi, 'illegalscript') }}></p>
        </div>
      )
    }

    if (data.UserID === undefined) {
      return (
        <div className="my-3">
          <div className="alert alert-danger">No User ID given.</div>
        </div>
      )
    }

    return (
      <div className="my-3">
        <form onSubmit={this.handleSubmit}>
          <div className='form-notice'>{AppLabels.ShipWithinCanadaNotice}</div>
          <div className='mb-3'>
            <label className="mb-0" htmlFor="num-kits-required">{AppLabels.IAMHowManyKits}</label>
            <Select id="num-kits-required"
              styles={SelectStyles}
              options={this.state.amountNeededOptions}
              name="AmountNeeded"
              defaultValue={this.state.amountNeededOptions.filter(x => x.value === data.Kits.find(x => x.KitTypeCode === Constants.HivKitCode)!.NumNeeded)}
              isSearchable={false}
              onChange={(value: any) => {
                data.Kits.find(x => x.KitTypeCode === Constants.HivKitCode)!.NumNeeded = value.value
                this.setState({ data: data })
              }}
            ></Select>
            <div className={`text-danger ${validated && (data.Kits.find(x => x.KitTypeCode === Constants.HivKitCode)!.NumNeeded < 1 || data.Kits.find(x => x.KitTypeCode === Constants.HivKitCode)!.NumNeeded > 5) ? "" : "d-none"}`}>{AppLabels.IAMHowManyKitsValidation}</div>
          </div>

          <div>
            <div
              id='double-check-warning'
              ref={this.doubleCheckWarningRef}
              className={confirmAddress ? "alert alert-warning mb-3" : "mb-3"}
              dangerouslySetInnerHTML={{ __html: confirmAddress ? AppLabels.DoubleCheckWarningConfirm : AppLabels.DoubleCheckWarningSubmit }}></div>

            <div className='mb-2 form-title'
              dangerouslySetInnerHTML={{ __html: AppLabels.NameTitle }}>
            </div>
            <div className="mb-3 row">
              <div className="col-12 col-md-6">
                <div className="mb-3">
                  <label className="mb-0 required" htmlFor="first-name">{AppLabels.FirstName}</label>
                  <input id="first-name"
                    className={`form-control ${validated && data.FirstName.trim().length <= 0 ? "border-danger" : ""}`}
                    name="FirstName"
                    value={data.FirstName}
                    type="text"
                    placeholder="John"
                    onChange={(event) => this.handleInputChange(event)} />
                  <div className={`text-danger ${validated && data.FirstName.trim().length <= 0 ? "" : "d-none"}`}>{AppLabels.FirstNameValidation}</div>
                </div>
              </div>
              <div className="col-12 col-md-6">
                <div>
                  <label className="mb-0 required" htmlFor="last-name">{AppLabels.LastName}</label>
                  <input id="last-name"
                    className={`form-control ${validated && data.LastName.trim().length <= 0 ? "border-danger" : ""}`}
                    name="LastName"
                    value={data.LastName}
                    type="text"
                    placeholder="Doe"
                    onChange={(event) => this.handleInputChange(event)} />
                  <div className={`text-danger ${validated && data.LastName.trim().length <= 0 ? "" : "d-none"}`}>{AppLabels.LastNameValidation}</div>
                </div>
              </div>
            </div>

            <div className='mb-2'>
              <div className="form-title">{AppLabels.AddressTitle}</div>
            </div>

            <div className="row">
              <div className="col-12 col-md-6">
                <div className="mb-3">
                  <label className="mb-0 required" htmlFor="building-number">{AppLabels.BuildingNumber}</label>
                  <input
                    id="building-number"
                    className={`form-control ${validated && data.BuildingNumber.trim().length < 1 ? "border-danger" : ""}`}
                    name="BuildingNumber"
                    value={data.BuildingNumber}
                    type="text"
                    placeholder="1"
                    onChange={(event) => this.handleInputChange(event)} />
                  <div className={`text-danger ${validated && data.BuildingNumber.trim().length < 1 ? "" : "d-none"}`}>{AppLabels.BuildingNumberValidation}</div>
                </div>
              </div>
              <div className="col-12 col-md-6">
                <div className="mb-3">
                  <label className="mb-0 required" htmlFor="street-name">{AppLabels.StreetName}</label>
                  <input
                    id="street-name"
                    className={`form-control ${validated && data.StreetName.trim().length < 3 ? "border-danger" : ""}`}
                    name="StreetName"
                    value={data.StreetName}
                    type="text"
                    placeholder={AppLabels.StreetNamePlaceholder}
                    onChange={(event) => this.handleInputChange(event)} />
                  <div className={`text-danger ${validated && data.StreetName.trim().length < 3 ? "" : "d-none"}`}>{AppLabels.StreetNameValidation}</div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-12 col-md-6">
                <div className="mb-3">
                  <label className="mb-0" htmlFor="unit">{AppLabels.Unit}</label>
                  <input
                    id="unit"
                    className="form-control"
                    name="Unit"
                    value={data.Unit}
                    type="text"
                    placeholder="1"
                    onChange={(event) => this.handleInputChange(event)} />
                </div>
              </div>
              <div className="col-12 col-md-6">
                <div className="mb-3">
                  <label className="mb-0 required" htmlFor="city">{AppLabels.City}</label>
                  <input
                    id="city"
                    className={`form-control ${validated && data.City.trim().length < 3 ? "border-danger" : ""}`}
                    name="City"
                    value={data.City}
                    type="text"
                    placeholder={AppLabels.CityPlaceholder}
                    onChange={(event) => this.handleInputChange(event)} />
                  <div className={`text-danger ${validated && data.City.trim().length < 3 ? "" : "d-none"}`}>{AppLabels.CityValidation}</div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-12 col-md-6">
                <div className="mb-3">
                  <label className="mb-0 required" htmlFor="province">{AppLabels.Province}</label>
                  <Select id="province"
                    styles={SelectStyles}
                    options={this.state.provinces}
                    name="Province"
                    value={this.state.provinces.filter(x => x.value === data.Province)}
                    isSearchable={false}
                    onChange={(value: any, event: any) => this.handleInputChange({ target: { ...event, value: value.value } })}
                  ></Select>
                  <div className={`text-danger ${validated && data.Province.trim().length < 2 ? "" : "d-none"}`}>{AppLabels.ProvinceValidation}</div>
                </div>
              </div>

              <div className="col-12 col-md-6">
                <div className="mb-3">
                  <label className="mb-0 required" htmlFor="postal-code">{AppLabels.PostalCode}</label>
                  <input
                    id="postal-code"
                    className={`form-control ${validated && data.PostalCode.trim().length !== 7 ? "border-danger" : ""}`}
                    name="PostalCode"
                    value={data.PostalCode}
                    type="text"
                    placeholder="A1A 1A1"
                    onChange={(event) => this.handleInputChange(event)} />
                  <div className={`text-danger ${validated && data.PostalCode.trim().length !== 7 ? "" : "d-none"}`}>{AppLabels.PostalCodeValidation}</div>
                </div>
              </div>
            </div>
          </div>

          {serverError !== undefined && serverError.trim().length > 0 && (
            <p className="mb-3 alert alert-danger" dangerouslySetInnerHTML={{ __html: serverError.replace(/(<? *script)/gi, 'illegalscript') }}></p>
          )}

          <div className="mb-3">
            {isSaving
              ? <button type="submit" disabled className="btn iam-submit w-100"><Spinner animation="border" variant="primary" className="me-2" size="sm" />{AppLabels.Saving}</button>
              : <button type="submit" className="btn iam-submit w-100">{confirmAddress ? AppLabels.Confirm : AppLabels.Submit}</button>

            }</div>
        </form>
      </div >
    )
  }
}