import chroma from "chroma-js";

/* eslint import/no-anonymous-default-export: [2, {"allowObject": true}] */
export default {
  /*container: (styles, { isDisabled, isFocused, isSelected }) => {
    const color = chroma("#000");
    return {
      ...styles,
      backgroundColor: "white",
      color: "#000",
      cursor: isDisabled ? "not-allowed" : "default",
      boxShadow: isFocused || isSelected ? "0 0 0 0.2rem rgb(0 0 0 / 25%)" : "",

      ":active": {
        ...styles[":active"],
        backgroundColor:
          !isDisabled && (isSelected ? color : color.alpha(0.3).css())
      }
    };
  },*/
  control: styles => ({ 
    ...styles, 
    backgroundColor: "white", 
    borderColor: "black", 
    borderRadius: 0,
    boxShadow: "none",
    fontSize: "16px",

    ":active": {
      borderColor: "black", 
      boxShadow: "0 0 0 0.2rem rgb(0 0 0 / 25%)"
    },
    ":hover": {
      borderColor: "black", 
      boxShadow: "0 0 0 0.2rem rgb(0 0 0 / 25%)"
    },
    ":focus": {
      borderColor: "black", 
      boxShadow: "0 0 0 0.2rem rgb(0 0 0 / 25%)"
    }
   }),
  option: (styles, { isDisabled, isFocused, isSelected }) => {
    const color = chroma("#000");
    return {
      ...styles,
      backgroundColor: isDisabled
        ? null
        : isSelected
        ? color.alpha(0.4).css()
        : isFocused
        ? color.alpha(0.1).css()
        : null,
      color: isDisabled ? "#ccc" : isSelected ? "white" : "#000",
      cursor: isDisabled ? "not-allowed" : "default",
      fontSize: "16px",

      ":active": {
        ...styles[":active"],
        backgroundColor:
          !isDisabled && (isSelected ? color : color.alpha(0.3).css())
      }
    };
  },
  multiValue: (styles, { data }) => {
    const color = chroma("#44596c");
    return {
      ...styles,
      backgroundColor: color.alpha(0.1).css()
    };
  },
  multiValueLabel: (styles, { data }) => ({
    ...styles,
    color: "#44596c"
  }),
  multiValueRemove: (styles, { data }) => ({
    ...styles,
    color: "#44596c",
    ":hover": {
      backgroundColor: "#44596c",
      color: "white"
    }
  }),
  /*singleValue: (styles, { data, isFocused, isSelected }) => {
    return {
      ...styles,
      boxShadow: isFocused || isSelected ? "0 0 0 0.2rem rgb(0 0 0 / 25%)" : ""
    };
  },*/
};
