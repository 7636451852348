import axios from "axios";
import { UserConfig } from "../common/types";

const action = "users";

export async function getUserConfig(userID: string): Promise<UserConfig> {
  return await axios.get("/api/" + action + "/my/" + userID)
  .then(response => {
    return response.data
  });
}
