import { UserKit, FormSubmissionKit, FormSubmissionResponse, KitCodesJson } from "./types"

export function stringTemplate(string: string, obj: any) {
    return Object.keys(obj).reduce((acc, key) => {
        return acc.replaceAll('{'+ key +'}', obj[key])
    }, string);
}

export function formatPostalCodeInput(pcd: string) {

    let isAlpha = (char: string) => char.match(/^[a-zA-Z]/)

    pcd = pcd.replace(/ {1,}/g, " "); // Remove all double spaces and replace with single
    pcd = pcd.replace(/(\W )/g, ''); // Remove all non alphanumerics and single spaces

    for (let i = 0; i < pcd.length; i++) {
        if ((i === 0 || i === 2 || i === 5) && !isAlpha(pcd[i])) {
            pcd = pcd.substring(0, i);
        } else if ((i === 1 || i === 4 || i === 6) && isNaN(Number(pcd[i]))) {
            pcd = pcd.substring(0, i);
        } else if (i === 3 && pcd[i] !== ' ') {
            pcd = pcd.substring(0, i) + ' ' + pcd.substring(i, pcd.length)
        } else { // i > 7, cut it back.
            pcd = pcd.substring(0, 7);
        }
    }

    return pcd.toUpperCase();
}

export function isValidInteger(str: string) {
    return !isNaN(parseInt(str)) && parseInt(str) > 0
}

export function getKitTokensJson(submission: FormSubmissionResponse) : KitCodesJson {
    let toReturn: KitCodesJson = {}

    submission.kits.forEach(x => {
        toReturn[x.kitTypeCode] = x.codes
    })

    return toReturn;
}

export function getNumKitsPreviouslyOrdered(kitsOrdered: UserKit[], kitKey: string) {
    return kitsOrdered.some(x => x.key === kitKey)
        ? kitsOrdered.find(x => x.key === kitKey)!.numKitsOrdered
        : 0
}

export function getNumKitsRemaining(kitsOrdered: UserKit[], kitKey: string) {
    return kitsOrdered.some(x => x.key === kitKey)
        ? kitsOrdered.find(x => x.key === kitKey)!.quotaRemaining
        : 0
}

export function getNumKitsNeeded(kits: FormSubmissionKit[], kitKey: string) {
    return kits.some(x => x.KitTypeCode === kitKey)
    ? kits.find(x => x.KitTypeCode === kitKey)!.NumNeeded
    : 0
}